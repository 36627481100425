<template>
  <div>
    <router-view v-if="isReady" />
    <div v-else>Loading...</div>
  </div>
</template>

<script>
export default {
  name: 'TempDataModule',
  data() {
    return {
      isReady: false,
    };
  },
  created() {
    this.bootstrap();
  },
  mounted() {},
  methods: {
    bootstrap() {
      this.isReady = true;
    },
  },
};
</script>

<style></style>
